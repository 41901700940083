<template>
  <div class="dataField">
    <div class="uk-form-label" :for="name">{{ name }}</div>
    <AutoSizeTextarea
        v-if="!disabled"
        :class="getDOMClassName(name)"
        :name="name"
        :model-value="value"
        :disabled="disabled"
        @change="onChange"
    ></AutoSizeTextarea>
    <div
        v-else
        class="value"
        :class="getDOMClassName(name)"
    >
      {{value}}
    </div>
  </div>
</template>

<script>
import AutoSizeTextarea from "@/components/AutoSizeTextarea";
import {useDOMClassname} from "@/use/useDOMClassname";
export default {
  name: "DataField",
  emits: ['change'],
  components: {
    AutoSizeTextarea,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    const onChange = value => emit('change', value)
    const { getDOMClassName } = useDOMClassname()
    return {
      onChange,
      getDOMClassName
    }
  }
}
</script>

<style scoped>
  .value {
    padding: 0 .5rem .5rem .5rem;
    background-color: white;
  }
</style>
