<template>
  <div class="transcriptMetaData uk-card uk-card-body">
    <form
        class="uk-form-stacked uk-width-1-1"
        v-on:submit.prevent=""
    >
      <button
          v-if="showCloseButton"
          class="close uk-button-danger"
          @click="onClose"
      >
        CLOSE
      </button>
      <fieldset class="uk-fieldset uk-width-1-1">
        <h2>{{ imageName }}</h2>
        <div class="flex uk-width-1-1">
          <div class="uk-width-1-2">
            <div class="marginRight uk-grid uk-grid-small" uk-grid>
              <div class="uk-width-1-1">
                <DataField
                    v-for="name of fields"
                    :key="name"
                    :name="name"
                    :disabled="['collectionName', 'documentName'].includes(name) || !isEditable"
                    :value="transcript[name]"
                    @change="setAttribute($event, name)"
                ></DataField>
                <button v-if="isEditor && isEditable" class="uk-button-small" :class="{'uk-button-primary':fieldsChanged}">Save Changes</button>
                <hr>
                <button
                    v-if="isAdmin && isEditable"
                    class="addField uk-button-small uk-button-primary"
                    :disabled="showAddField"
                    @click="onAddField"
                >
                  Add Field
                </button>
                <div
                    class="addField uk-margin"
                    v-if="showAddField"
                >
                  <div class="uk-alert-primary">
                    <p>
                      Name the new meta data field
                    </p>
                  </div>
                  <div class="uk-form-label">
                    <input
                        v-model="newFieldName"
                        type="text"
                    >
                  </div>
                  {{ newFieldName }}
                  <button
                      class="uk-button-small uk-button-primary"
                      @click="onNewFieldName"
                  >Create Field
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
              class="uk-width-1-2"
          >
            <fieldset class="uk-fieldset">
              <div class="uk-form-label">transcript</div>
              <pre class="transcript">{{ transcript.transcript }}</pre>
            </fieldset>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import {PATCH_TRANSCRIPT} from '@/store/operations'
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {getBaseUrl} from "@/plugins/lib/getBaseUrl"
import DataField from "@/components/Transcripts/DataField";
import {useGlobalMetaDataTags} from "@/use/useGlobalMetaDataTags";

export default {
  name: "TranscriptMetaData",
  components: {
    DataField,
  },
  props: {
    id: {
      type: String,
      required: true
    },
    imageName: {
      type: String,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    storeCollectionName: {
      type: String,
      default: 'pages'
    }
  },
  emits: ['rendered','close'],
  setup(props, {emit}) {
    const store = useStore()
    const {globalTagNames, listGlobalTags} = useGlobalMetaDataTags()
    const isAdmin = () => this.$store.state.user?.isAdmin
    const isEditor = () => this.$store.state.user?.isAdmin || this.$store.state.user?.isEditor
    const transcript = computed(() => store.state[props.storeCollectionName].find(page => page.documentName === props.id && page.imageName === props.imageName))
    const imageUrl = computed(() => `${getBaseUrl()}transkribus/${props.imageName}`)
    const newFieldName = ref('')
    const newFieldMessage = ref('')
    const showAddField = ref(false)
    const showAddFieldValue = ref(false)
    const showImageThumbnail = ref(false)
    const fieldsChanged = ref(false)
    const setAttribute = (value, attributeName) => {
      const oldValue = transcript[attributeName]
      fieldsChanged.value = value !== oldValue

      if (fieldsChanged.value) {
        syncTranscript({
          id: transcript.value.documentName,
          imageName: transcript.value.imageName,
          attributeName,
          value
        })
        .then(() => fieldsChanged.value = false)
      }
    }
    const syncTranscript = patch => store.dispatch(PATCH_TRANSCRIPT, patch)

    const fields = computed(() => {
          const disabled = ['transcript', 'imageName']
          let fields = [...globalTagNames.value]
          let transcript = store.state[props.storeCollectionName].find(page => page.documentName === props.id && page.imageName === props.imageName)
          if (transcript) {
            fields = Object.keys(transcript).reduce((fields, name) => {
              const isViableField = !disabled.includes(name) && !fields.includes(name)
              if (isViableField) fields.push(name)
              return fields
            }, fields)
          }
          return fields.sort()
        }
    )

    onMounted(() => emit('rendered'))

    const onClose = () => emit('close')
    const onAddField = () => {
      newFieldMessage.value = 'Please name the new meta data field.'
      showAddField.value = true
      newFieldName.value = ''
    }
    const onNewFieldName = () => {
      const nameExists = Object.keys(transcript.value).includes(newFieldName.value)
      newFieldName.value = newFieldName.value.trim()
      if (!newFieldName.value) newFieldMessage.value = `a name is required`
      else if (nameExists) newFieldMessage.value = `"${newFieldName.value}" exists already or is reserved`
      else if (newFieldName.value.includes('/')) newFieldMessage.value = `field names cannot contain "/"`
      else {
        setAttribute({target: {value: ''}}, newFieldName.value)
        showAddField.value = false
        newFieldName.value = ''
        newFieldMessage.value = ''
      }
    }

    listGlobalTags()

    return {
      imageUrl,
      isAdmin,
      isEditor,
      fieldsChanged,
      newFieldMessage,
      newFieldName,
      onAddField,
      onNewFieldName,
      onClose,
      setAttribute,
      showAddField,
      showAddFieldValue,
      showImageThumbnail,
      transcript,
      fields,
    }
  },
}
</script>

<style scoped>

.flex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.marginRight {
  margin-right: 1rem;
}

.transcript {
  margin-top: .2rem;
  min-height: 100%;
}

.transcriptMetaData {
  background-color: rgb(250, 241, 217);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.close {
  position: absolute;
  right: 2rem;
  background-color: darkred;
  color: white;
}
</style>
