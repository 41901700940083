<template>
  <div class="lFixedAndFraction">
    <div class="fixed">
      <slot name="fixed">

      </slot>
    </div>
    <div class="fraction">
      <slot name="fraction">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LFixedAndFraction"
}
</script>

<style scoped>
.lFixedAndFraction {
  display: flex;
  flex-direction: row;
}
.fixed {
  flex: 0 0 50%;
}
.fraction {
  flex: 1 0 max-content;
}
</style>
