<template>
  <div class="searchTranscripts uk-section">
    <div class="uk-container">
      <h1>Search Møns Tugthus Letters</h1>
      <form class="uk-form">
        <fieldset class="uk-padding">
          <legend style="font-weight: bolder; font-size: 150%">Search Fields</legend>
          <div>
            <div
                v-for="name in filterAttributes"
                :key="name"
                class="grid uk-margin"
            >
              <label>{{ name[0].toUpperCase() + name.substring(1) }}</label>
              <div class="uk-form-row">
                <input
                    type="text"
                    :placeholder="`Search in ${ name }`"
                    class="uk-width-1-1"
                    :model-value="filter[name]"
                    @input="onFilterAttributeChange($event, name)"
                >
              </div>
            </div>
          </div>

          <div class="grid uk-margin-top">
            <span>&nbsp;</span>
            <input
                type="button"
                placeholder="Search in transcripts"
                class="uk-button uk-button-primary"
                value="Search"
                @click="performSearch"
            >
          </div>
        </fieldset>
      </form>
      <hr/>
      <SearchResults
          :filterUsedForResults="filterUsedForResults"
          :page="page"
      ></SearchResults>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {LIST_TRANSCRIPT_FIELDS, SEARCH_COLLECTION} from "@/store/operations";
import SearchResults from "@/components/Transcripts/Search/SearchResults";
import {useGlobalMetaDataTags} from "@/use/useGlobalMetaDataTags";

export default {
  name: "SearchTranscripts",
  components: {
    SearchResults,
  },
  props: {
    page: {
      type: String,
      default: '0'
    }
  },
  setup() {
    const store = useStore()
    const itemCount = computed(() => store.state.results.length)
    const searchPerformed = ref(false)
    const {globalTagNames, listGlobalTags} = useGlobalMetaDataTags()
    const filter = ref({})
    const filterUsedForResults = ref(null)

    const filterAttributes = computed(() => {
      let filter = [...(globalTagNames.value ?? [])]
      store.state.transcriptFields.forEach(name => {
        if (!filter.includes(name)) filter.push(name)
      })
      filter = filter.sort((a, b) => b.toLowerCase() - a.toLowerCase())

      return filter
    })

    const onFilterAttributeChange = (event, name) => {
      filter.value[name] = event.target.value
    }

    const searchFilter = computed(() => {
      const searchFilter = {}
      for (let name of filterAttributes.value) {
        if (filter.value[name]) searchFilter[name] = filter.value[name]
      }
      return searchFilter
    })

    const performSearch = () => {
      filterUsedForResults.value = {...searchFilter.value}
      store.dispatch(SEARCH_COLLECTION, searchFilter.value)
    }

    onMounted(() => {
      store.dispatch(LIST_TRANSCRIPT_FIELDS)
      listGlobalTags()
    })

    return {
      filterAttributes,
      filterUsedForResults,
      itemCount,
      onFilterAttributeChange,
      performSearch,
      searchPerformed,
      filter,
    }
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 150px 1fr;
}
</style>
