<template>
  <div class="searchResults">
    <h2>
      Search Results
    </h2>
    <div
        v-if="!results || !resultsAreValid"
        class="results"
    >
      No Results
    </div>
    <div v-else>
      <div class="download">
        <button
            class="uk-button"
            type="button"
            @click="onDownload"
            :disabled="downloading"
        >
          <span v-if="!downloading">Download Zipped Results</span>
          <span v-else>downloading, please wait...</span>
        </button>
      </div>
      <PaginatedList
          itemLabel="transcript"
          :itemCount="itemCount"
          :listUrl="`search`"
          :pageSize="pageSize"
          :page="currentPage"
      />
      <LResultsPageListItem
          v-for="(page, index) in results"
          :key="index"
          :expand="expand === index"
          :name="`${page.documentName}/${page.name}`"
          :index="index"
          :url="page.url"
          :transcript="page.transcript"
          :searchFilter="filterUsedForResults"
          storeCollectionName="results"
          @toggle="onToggle(index)"
      />
    </div>
  </div>
</template>

<script>
import LResultsPageListItem from "@/components/Transcripts/Search/LResultsPageListItem";
import PaginatedList from "@/components/paginatedList/PaginatedList";
import {computed, ref, watch} from "vue";
import {getBaseUrl} from "@/plugins/lib/getBaseUrl";
import {getReadableTImestamp} from "@/components/Transcripts/lib/getReadableTImestamp";
import {createReadmeFile} from "@/components/Transcripts/lib/createReadmeFile";
import createZipFile from "@/components/Transcripts/lib/createZipFile";
import {downloadZipFile} from "@/components/Transcripts/lib/downloadFile";
import {usePaginatedList} from "@/components/paginatedList/use/usePaginatedList";
import {useStore} from "vuex";

export default {
  name: "SearchResults",
  emits: ['download'],
  components: {
    PaginatedList,
    LResultsPageListItem,
  },
  props: {
    filterUsedForResults: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const store = useStore()
    const downloading = ref(false)
    const zipFileName = ref('')
    const expand = ref(-1)
    const resultsAreValid = ref(false)
    const searchFilter = computed(() => props.filterUsedForResults)
    const {
      currentPage,
      getItemPage,
      pageSize,
    } = usePaginatedList(props)

    const allResults = computed(() => [...store.state.results]
        .sort((a, b) => a.imageName > b.imageName))

    const itemCount = computed(() => allResults.value?.length ?? 0)
    const results = computed(() => {
      const sortedList = [...store.state.results]
          .sort((a, b) => a.imageName > b.imageName)
          .map(item => [item.imageName, item])
      return getItemPage(sortedList, (name, transcript) => {
        return {
          name: name.substring(0, name.lastIndexOf('.')),
          documentName: transcript.documentName,
          url: `${getBaseUrl()}transkribus/${name}`,
          transcript
        }
      })
    })

    const downloadZippedResults = async () => {
      downloading.value = allResults.value?.length
      let zipFile = null
      if (allResults.value?.length) {
        const files = []
        files.push(createReadmeFile(props.filterUsedForResults))
        files.push({
          name: 'results.json',
          content: JSON.stringify(allResults.value) // array of pages
        })
        zipFile = await createZipFile(files)
        downloadZipFile(zipFileName.value, zipFile)
      }
    }

    watch(searchFilter, () => {
      downloading.value = false
      resultsAreValid.value = false
    })
    watch(allResults, results => {
      if (results.length) {
        zipFileName.value = `MønsTugthus_results_${getReadableTImestamp()}.zip`
        resultsAreValid.value = true
      }
    })

    const onToggle = index => {
      const toggleOff = expand.value === index
      expand.value = toggleOff ? -1 : index
    }

    const onDownload = () => downloadZippedResults()

    pageSize.value = 20

    return {
      currentPage,
      downloading,
      expand,
      itemCount,
      onDownload,
      onToggle,
      pageSize,
      results,
      resultsAreValid,
      searchFilter
    }
  }
}
</script>

<style scoped>

</style>
