export const createReadmeFile = filter => {
  const file = {
    name: 'README.md',
    content: `# Search results from the Møns Tugthus Collection

The results listed in results.json were matched using the following filter:         
`
  }
  const serializedFilter = JSON.stringify(filter, null, 4)
  file.content += serializedFilter
  return file
}
