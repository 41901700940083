export const getReadableTImestamp = () => {
  let str = ""

  const currentTime = new Date()

  const year = currentTime.getYear()
  let month = currentTime.getMonth() + 1
  let day = currentTime.getDate()

  let hours = currentTime.getHours()
  let minutes = currentTime.getMinutes()
  let seconds = currentTime.getSeconds()

  if (month < 10) month = "0" + month
  if (day < 10) day = "0" + day

  if (hours < 10) hours = "0" + hours
  if (minutes < 10) minutes = "0" + minutes
  if (seconds < 10) seconds = "0" + seconds

  str += `${day}-${month}-${year}_${ hours }.${ minutes }.${ seconds }`

  return str;
}
