<template>
  <div class="lResultsPageListItem">

    <ul uk-accordion>
      <li>
        <a class="uk-accordion-title" href="#">{{name}}</a>
        <div class="uk-accordion-content">
          <ul uk-tab>
            <li class="uk-active">
              <a href="#" @click="selectedTab = 0">Meta Data</a>
            </li>
            <li>
              <a href="#" @click="selectedTab = 1">Transcript</a>
            </li>
          </ul>
          <div
              v-show="selectedTab === 0"
              class="tabContent"
          >
            <TranscriptMetaData
                v-if="transcript"
                :id="transcript.documentName"
                :isEditable="false"
                :imageName="transcript.imageName"
                :storeCollectionName="storeCollectionName"
                :showCloseButton="false"
                @rendered="onRendered"
            />

          </div>
          <div
              v-show="selectedTab === 1"
              class="tabContent"
          >
            <LFixedAndFraction>
              <template #fixed>

                <img
                    :src="url"
                    :alt="name"
                />

              </template>
              <template #fraction>
                <LineHeightAndOffset
                    @change="updateStyling"
                ></LineHeightAndOffset>
                <pre
                    class="transcript"
                    :style="preStyle"
                >{{ transcript.transcript }}</pre>
              </template>

            </LFixedAndFraction>

          </div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import LFixedAndFraction from "@/components/LFixedAndFraction";
import LineHeightAndOffset from "@/components/Transcripts/LineHeightAndOffset";
import TranscriptMetaData from "@/components/Transcripts/TranscriptMetaData";
import {computed, onMounted, ref} from "vue";
import {useDOMClassname} from "@/use/useDOMClassname";

export default {
  name: "LResultsPageListItem",
  emits: ['toggle'],
  components: {
    LFixedAndFraction,
    LineHeightAndOffset,
    TranscriptMetaData,
  },
  props: {
    url: {
      type: String,
      required: true
    },
    transcript: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    index: {
      type: Number,
    },
    expand: {
      type: Boolean,
      default: false
    },
    storeCollectionName: {
      type: String,
      default: 'pages'
    },
    searchFilter: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, {emit}) {
    const { getDOMClassName } = useDOMClassname()
    const preLineHeight = ref(1.5)
    const offset = ref(10)
    const selectedTab = ref(0)
    const preStyle = computed(() => {
          return {
            paddingTop: offset.value + 'px',
            lineHeight: preLineHeight.value + 'rem',
          }
        })
    const updateStyling = values => {
      preLineHeight.value = values.lineHeight
      offset.value = values.offset
    }
    const searchFilter = ref(props.searchFilter)

    const highlightMatches = () => Object.keys(searchFilter.value ?? {}).forEach(highlightFieldMatches)
    const highlightFieldMatches = fieldName => {
      const fieldMatchPattern = props.searchFilter?.[fieldName] ?? ''
      if (fieldMatchPattern) {
        const rx = new RegExp(fieldMatchPattern, 'gi')
        const matchableFieldElements = document.querySelectorAll('.' + getDOMClassName(fieldName))
        for (let i = 0; i < matchableFieldElements.length; i++) {
          const el = matchableFieldElements[i]
          el.innerHTML = el.innerHTML.replaceAll(rx, '<span class="highlight">$&</span>')
        }
      }
    }
    const onToggle = () => emit('toggle', props.index)

    onMounted(highlightMatches)

    const onRendered = () => {
      highlightFieldMatches()
    }
    return {
      onRendered,
      onToggle,
      preStyle,
      selectedTab,
      updateStyling,
    }
  }
}
</script>

<style scoped>
  .tabContent {
    min-height: 100%;
  }
</style>
