<template>
  <div class="uk-section">
    <div class="document">
      <h2 :title="collectionName">
        {{ documentName }}
      </h2>
      <PaginatedList
          itemLabel="transcript"
          :itemCount="itemCount"
          :listUrl="`documents/${documentName}`"
          :pageSize="pageSize"
          :page="currentPage"

      />
      <LPageListItem
          v-for="(page, index) in pages"
          :key="index"
          :expand="expand === index"
          :name="page.name"
          :index="index"
          :url="page.url"
          :transcript="page.transcript"
          @toggle="onToggle(index)"
      />
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {GET_DOCUMENT} from "@/store/operations";
import {useStore} from "vuex";
import PaginatedList from "@/components/paginatedList/PaginatedList";
import LPageListItem from "@/components/Transcripts/LPageListItem";
import {usePaginatedList} from "@/components/paginatedList/use/usePaginatedList";
import {getBaseUrl} from "@/plugins/lib/getBaseUrl";

export default {
  name: "Document",
  components: {
    PaginatedList,
    LPageListItem,
  },
  props: {
    id: {
      type: String,
      required: true
    },
    page: {
      type: String,
      default: '0'
    }
  },
  setup(props) {
    const store = useStore()
    const documentName = computed(() => {
      console.log(12, 'collectionName', store.state.pages[0]?.documentName ?? props.id, props.id)
      return store.state.pages[0]?.documentName ?? props.id // :S
    })
    const collectionName = computed(() => store.state.pages[0]?.collectionName ?? '') // :S
    const itemCount = computed(() => store.state.pages.length)
    const expand = ref(-1)
    const {
      currentPage,
      getItemPage,
      pageSize,
    } = usePaginatedList(props)

    pageSize.value = 12

    store.dispatch(GET_DOCUMENT, {id: encodeURIComponent(props.id)})
    // const loadDocument = name => router.push({name: 'Document', params: {id: name}})

    const pages = computed(() => {
      const sortedList = [...store.state.pages]
          .sort((a, b) => a.imageName > b.imageName)
          .map(item => [item.imageName, item])
      return getItemPage(sortedList, (name, transcript) => {
        return {
          name: name.substring(0, name.lastIndexOf('.')),
          url: `${getBaseUrl()}transkribus/${name}`,
          transcript: transcript,
        }
      })
    })

    const onToggle = index => {
      const toggleOff = expand.value === index
      expand.value = toggleOff ? -1 : index
    }

    return {
      collectionName,
      currentPage,
      documentName,
      expand,
      itemCount,
      onToggle,
      pages,
      pageSize,
    }
  }
}
</script>

<style scoped>
.document {
  padding-left: 1rem
}
</style>
