import JSZip from "jszip"
import b64ToBlob from "b64-to-blob";

export default async (files) => {
  const zip = new JSZip()

  for (let file of files) {
    const {name, content} = file
    zip.file(name, content)
  }
  return createBlob(await zip.generateAsync({ type: "base64" }))
}
const createBlob = (b64, mimeType="application/zip") => b64ToBlob(b64, mimeType)
