<template>
  <div class="lPageListItem">

    <h3 @click="onToggle">
      {{ name }}
    </h3>

    <TranscriptMetaData
        v-if="transcript"
        v-show="showMetaData"
        :isEditable="isEditable"
        :id="transcript.documentName"
        :imageName="transcript.imageName"
        :storeCollectionName="storeCollectionName"
        @close="showMetaData=false"
    />

    <LFixedAndFraction
        v-if="expand"
    >
      <template #fixed>

        <img
            :src="url"
            :alt="name"
            @click="showMetaData = !showMetaData"
        />

      </template>
      <template #fraction>
        <LineHeightAndOffset
            @change="updateStyling"
        ></LineHeightAndOffset>
        <pre
            class="transcript"
            :style="preStyle"
        >{{ transcript.transcript }}</pre>
      </template>

    </LFixedAndFraction>

  </div>
</template>

<script>
import LFixedAndFraction from "@/components/LFixedAndFraction";
import LineHeightAndOffset from "@/components/Transcripts/LineHeightAndOffset";
import TranscriptMetaData from "@/components/Transcripts/TranscriptMetaData";
import {computed, ref, watch} from "vue";

export default {
  name: "LPageListItem",
  emits: ['toggle'],
  components: {
    LFixedAndFraction,
    LineHeightAndOffset,
    TranscriptMetaData,
  },
  props: {
    url: {
      type: String,
      required: true
    },
    transcript: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
    },
    expand: {
      type: Boolean,
      default: false
    },
    storeCollectionName: {
      type: String,
      default: 'pages'
    }
  },
  setup(props, {emit}) {
    const onToggle = () => emit('toggle', props.index)
    const showMetaData = ref(false)
    const preLineHeight = ref(1.5)
    const offset = ref(10)
    const preStyle = computed(() => {
          return {
            paddingTop: offset.value + 'px',
            lineHeight: preLineHeight.value + 'rem',
          }
        })
    const updateStyling = values => {
      preLineHeight.value = values.lineHeight
      offset.value = values.offset
    }
    const src = ref(props.url)
    watch(src, imageSrc => {
      if (imageSrc) {
        console.log(343, imageSrc)
        require(imageSrc)
      }
    })
    return {
      onToggle,
      preStyle,
      showMetaData,
      updateStyling,
    }
  }
}
</script>

<style scoped>

</style>
