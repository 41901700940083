<template>
  <div
      v-if="page"
      class="pageListItem"
  >
    accordion...
    <div>Page {{ page.name}}</div>
    <img :src="page.url" width="200">
    <pre>{{ page.transcript }}</pre>
  </div>
</template>

<script>
export default {
  name: "PageListItem",
  props: {
    number: {
      type: Number,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
