<template>
  <div class="lineHeightAndOffset" uk-grid>
    <div class="uk-width-1-2">
      <span>Offset</span>
      <form class="uk-form">
        <div class="uk-width-1-1">
          <input
              class="uk-width-1-1"
              type="range"
              min="1"
              max="600"
              v-model="offset"
              @input="onOffsetChange"
          />
        </div>
      </form>
    </div>
    <div class="uk-width-1-2">
      <span>Line height</span>
      <form class="uk-form">
        <div class="uk-width-1-1">
          <input
              class="uk-width-1-1"
              type="range"
              min="0.9"
              max="4"
              step="0.1"
              v-model="lineHeight"
              @input="onLineHeightChange"

          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "LineHeightAndOffset",
  emits: ['change'],
  setup(props, {emit}) {
    const offset = ref(10)
    const lineHeight = ref(1.5)
    const onOffsetChange = event => {
      offset.value = event.target.value
      emitValues()
    }
    const onLineHeightChange = event => {
      lineHeight.value = event.target.value
      emitValues()
    }
    const emitValues = () => emit('change', {
      offset: offset.value,
      lineHeight: lineHeight.value,
    })
    return {
      offset,
      lineHeight,
      onOffsetChange,
      onLineHeightChange,
    }
  }
}
</script>

<style scoped>
.lineHeightAndOffset {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
