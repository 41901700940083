<template>
    <textarea
        class="textarea"
        :name="name"
        :disabled="disabled"
        @change="onChange($event)"
        :value="modelValue"
    ></textarea>
</template>

<script>
export default {
  name: "AutoSizeTextarea",
  emits: ['change'],
  props: {
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      required: false,
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const emitChange = value => emit('change', value)
    const onChange = event => {
      const textarea = event.target
      resize(textarea)
      emitChange(textarea.value)
    }

    const resize = textarea => {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight + 10}px`
    }
    return {
      onChange,
    }
  }
}
</script>

<style scoped>
.textarea {
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 0.3rem;
  font-size: inherit;
  outline: none;
  padding: 0.2rem 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  line-height: 105%;
}
</style>
